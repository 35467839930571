<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="New Industry" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="newIndustry.industryName"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Industry Name" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'

/** This component contains the code to add a new industry to the database */
export default {
  name: 'AddIndustry',
  components: {
    AdminHeaderVue,
    InputVue
  },
  data () {
    return {
      newIndustry: {
        industryName: null
      },
      submitted: false,
      isValidName: false
    }
  },
  methods: {
    ...mapActions('industries', ['addIndustry']),
    /** This method defines the behavior for when the industry name (Input value) is changed
     * @param name {String} - The name of the new industry
     * @param isValid {Boolean}
     * @public
     */
    onChangeName (name, isValid) {
      this.newIndustry.industryName = name
      this.isValidName = isValid
    },
    /** This method validates the input and submits the form
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (!(this.isValidName)) {
        this.addIndustry(this.newIndustry)
      }
    },
    /** This method redirects the user back to the industry page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/industry')
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
   margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .upload {
          width: 200px;
          height: 60px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          img {
            width: 200px;
            height: 60px;
            object-fit: contain;
            padding: 5px;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            background: #2780eb;
            color: white;
            border: none;
            padding: 5px 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
